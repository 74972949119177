.track-addons .addons-listing {

  h2,
  p {
    margin: 0;
    text-align: left;
  }

  .card-listing {
    padding: 25px;
    margin: 0 0 25px;
    border: 1px solid #eee;
    background-color: transparent;





    .single-product {
      margin-top: 20px;

      .meta-data .addon-actions button {
        width: 100%;
      }
    }

    .view-more-btn {
      font-size: 18px;
      margin: 30px auto 0;
      max-width: fit-content;
    }
  }

  .track-addons-panel {
    margin-top: 25px;
    overflow-y: auto;
    max-height: 992px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    & ::-webkit-scrollbar-thumb {
      border-radius: 50%;
      background-color: #eee;
    }


    .addon-expiry {
      top: 38px;
      left: 12px;
    }

    .addon-ribbon {
      top: 14px;
      right: 5px;
    }

    .row {
      >div {
        position: relative;
      }
    }

  }
}

.addons-listing {
  h2 {
    font-size: 36px;
    font-weight: 800;
    text-align: center;

    +p {
      color: #777;
      line-height: 20px;
      text-align: center;
    }
  }

  .skip-btn {
    padding: 10px 0;
    color: #05c3de;
    cursor: pointer;
    font-weight: bold;
    transform: translateX(80px);
    text-transform: uppercase;

    svg {
      margin: 0;
      font-size: 12px;
      transform: translateY(-1px);
    }
  }

  .addons {
    .addon-expiry {
      border-radius: 0px 10px 10px 0px;
      top: 40px;
      left: 13px;
    }

    .addon-ribbon {
      top: 19px;
      right: 5px;
    }

    .row {
      >div {
        position: relative;
      }
    }
  }

  .card-listing {
    margin-top: 45px;
    border-radius: 8px;
    padding: 45px 55px 55px;
    background-color: #fafafa;

    h4 {
      margin: 0;
      color: #444;
      // font-size: 28px;
      font-weight: 800;
      text-transform: uppercase;
    }

    .slick-slider {
      .slick-list {
        .slick-slide {
          >div {
            padding: 0 15px;
          }
        }
      }

      .slick-arrow::before {
        font-size: 28px;
        color: #05c3de;
        font-family: "Font Awesome 5 Pro";
      }

      .slick-arrow.slick-next::before {
        content: "\f054";
      }

      .slick-arrow.slick-prev::before {
        content: "\f053";
      }

      .slick-dots {
        right: 0;
      }
    }

    .single-product {
      margin-top: 25px;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid #eee;

      .thumbnail {
        height: 250px;
        background-size: cover;
        border-radius: 8px 8px 0 0;
      }

      .meta-data {
        padding: 25px;

        h5 {
          margin: 0;
          font-weight: 600;
        }

        span {
          display: block;
          font-size: 14px;
          font-weight: 500;
          margin: 2px 0 10px;
        }

        h6 {
          color: #777;
          // font-size: 18px;
          margin-top: 5px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: #05c3de;
        }

        p.not-available {
          color: #dc3545;
        }

        .addon-actions {
          margin-top: 20px;

          button {
            font-size: 12px;
            padding: 8px 15px;
            line-height: 18px;
            margin-bottom: 5px;

            svg {
              margin: 0 0 0 5px;
            }

            &.rounded-left {
              border-radius: 0 0 0 15px;
            }

            &:last-child {
              border-radius: 0 0 15px 0;
            }

            &.remove {
              background-color: #1b2a36;

              &:hover {
                border-color: #1b2a36;
                color: #1b2a36 !important;
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .prev-quotes-btn {
      margin-top: 45px;
      max-width: fit-content;
    }
  }

  .actions {
    margin-top: 45px;
  }
}

.user-cart {
  top: 50%;
  z-index: 11;
  right: 15px;
  width: 84px;
  height: 82px;
  display: flex;
  position: fixed;
  cursor: pointer;
  align-items: center;
  border-radius: 100%;
  transition: all 0.4s;
  justify-content: center;
  background-color: #05c3de;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  svg {
    color: #fff;
    line-height: 0;
    font-size: 36px;
  }

  span {
    top: 12px;
    right: 12px;
    width: 25px;
    height: 24px;
    color: #fff;
    display: flex;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    padding-top: 2px;
    transition: 0.3s;
    position: absolute;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    background-color: #ffb71b;
  }

  &:hover {
    transition: 0.3s;
    background-color: #1b2a36;
  }
}

.addon-desc-modal {
  padding: 30px 60px 55px;

  .addon-share-container {
    gap: 8px;
    display: flex;
    margin-top: 10px;
  }

  .addon-price-container {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: grid;
    -webkit-box-align: center;
    place-items: center start;
    grid-template-columns: auto 1fr;
    gap: 12px 8px;
    justify-content: center;

    .addon-price-was {
      color: #05c3de;
      text-decoration: line-through;
      font-size: 14px;
      font-weight: 500;
    }

    .addon-price-now {
      color: #05c3de;
      font-size: 20px;
      font-weight: 900;
    }

    .addon-price-discount {
      color: #05c3de;
      font-size: 16px;
      font-weight: 600;
    }

    .addon-price-label {
      color: #ffb71b;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .close-icon {
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: flex-end;

    svg {
      width: 18px;
      height: 18px;
      padding: 4px;
      display: flex;
      font-size: 18px;
      font-weight: 200;
      transition: 0.3s;
      text-align: center;
      align-items: center;
      border-radius: 100%;
      justify-content: center;
      border: 1px solid #000;

      &:hover {
        color: #fff;
        transition: 0.3s;
        background-color: #000;
      }
    }
  }

  .content {
    margin-top: 30px;

    >img {
      width: 100%;
    }

    h2 {
      font-size: 32px;
      font-weight: 800;
      margin-top: 40px;
      text-align: center;
    }

    h4 {
      font-size: 28px;
      font-weight: 600;
      color: #05c3de;
      line-height: 32px;
      margin-top: 15px;
      text-align: center;
    }

    .addon-desc {
      margin-top: 20px;
    }

    .play-video {
      max-height: 600px;
      width: 100%;
    }

    .recommended-offers-images {
      display: flex;
      margin-top: 20px;
    }

    .recommended-offers-images img {
      width: 150px;
      height: 100px;
      cursor: pointer;
      margin: 0 10px 0 0;
    }

    .recommended-offers-images video {
      height: 100px;
      width: 200px;
    }

    .recommended-offers-images .selected {
      outline: 5px solid #05c3de;
    }

    .video-wrapper {
      cursor: pointer;
    }

    .video-container {
      width: 100%;
      height: auto;
      opacity: 0.5;
      position: relative;
      pointer-events: none;
    }

    .video-wrapper video {
      height: 100px;
    }

    p,
    li {
      line-height: 22px;
      margin-bottom: 8px;
    }

    .bullets {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 25px;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
        color: #666;
        padding: 0 12px;
        border-right: 1px solid #ddd;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: 0;
        }

        span {
          color: #333;
          font-weight: 600;
        }
      }
    }

    .cart-btn {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      align-items: center;
      justify-content: space-between;

      p {
        color: #666;

        span {
          color: #333;
          font-weight: 600;
        }

        &.bullet span {
          margin-bottom: 2px;
          display: inline-block;
        }
      }

      button {
        svg {
          margin: 0 0 0 5px;
        }

        &.remove {
          background-color: #1b2a36;

          &:hover {
            border-color: #1b2a36;
            color: #1b2a36 !important;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.offcanvas {
  padding: 20px 30px;

  .offcanvas-header {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;

    h6 {
      margin: 0;
      font-size: 18px;
      font-weight: 800;
    }

    svg {
      color: #777;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .offcanvas-body {
    padding: 0;
    overflow-x: hidden;
    padding-right: 5px;
    border-top: 1px solid #eee;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #eee;
    }

    .product {
      display: flex;
      padding: 25px 0;
      justify-content: space-between;
      border-bottom: 1px solid #eee;

      p {
        color: #444;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 3px;
        padding: 0 15px 0 0;
      }

      .values {
        margin-top: 5px;
      }

      span.remove {
        display: block;
        cursor: pointer;
        font-size: 14px;
        margin-top: 8px;
        color: #dc3545;
        max-width: fit-content;

        svg {
          margin: 0 3px 0 0;
        }
      }

      img {
        width: 80px;
      }
    }

    .cart-subtotal {
      display: flex;
      padding: 15px 0 0;
      justify-content: space-between;

      label,
      span {
        font-weight: 600;
      }
    }

    .controls {
      display: flex;
      margin-top: 15px;
      justify-content: center;

      >button {
        width: 50%;
        font-size: 13px;
        padding: 8px 20px;
        border-radius: 0 0 0 15px;

        &:last-child {
          border-radius: 0 0 15px 0;
        }
      }
    }

    h6 {
      color: #ccc;
      padding-top: 25px;
      text-align: center;
    }
  }
}

.product {
  span.price {
    color: #05c3de;
    font-weight: 600;
  }

  span.variant-quantity,
  span.item-quantity {
    color: #ccc;
    font-size: 12px;
    margin: 0 0 0 7px;
    display: inline-block;
  }

  .values {
    user-select: none;
  }

  .counter {
    color: #666;
    font-weight: 500;

    >span {
      line-height: 0;
      margin: 0 10px;
      cursor: pointer;
      padding: 2px 3px;
      display: inline-block;
      border: 1px solid #ddd;

      svg {
        font-size: 12px;
      }

      &.false {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}

.track-addons.checkout {
  padding-top: 40px;
}

.checkout {
  h4 {
    color: #444;
    font-weight: 800;
    text-transform: uppercase;
  }

  .coupon-content {
    margin-top: -5px;

    .input-wrapper {
      form {
        width: 100%;

        label {
          z-index: 1;
          color: #333;
          padding: 0 8px;
          font-size: 14px;
          font-weight: 600;
          position: relative;
          background-color: #fff;
          transform: translate(10px, 13px);
        }

        input {
          margin: 0;
          width: 100%;
          padding: 20px 120px 18px 15px;
        }

        button {
          top: 0;
          bottom: 0;
          padding: 0;
          right: 15px;
          border: none;
          font-size: 14px;
          font-weight: 600;
          color: #05c3de;
          position: absolute;
          background-color: transparent !important;
        }
      }
    }
  }

  .pay-form {
    margin-top: 40px;

    .card-and-terms {
      .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        label {
          width: 49%;
          height: 100%;
          display: flex;
          cursor: pointer;
          margin-top: 10px;
          padding: 5px 15px;
          border-radius: 8px;
          align-items: center;
          border: 1px solid #eee;
          background-color: #fafafa;

          img {
            width: 55px;
            aspect-ratio: 3/2;
            object-fit: contain;
            margin: 0 0 0 10px;
          }
        }
      }

      .terms {
        margin: 25px 0 20px 0;

        a {
          color: #05c3de;
        }

        >div:first-child {
          margin-bottom: 8px;
        }
      }

      .checkout-actions button {
        margin: 0;
        width: 100%;
      }

      .red-color {
        font-size: 16px;
      }
    }

    .pay-head {
      margin-top: 30px;

      span {
        display: block;
        cursor: pointer;
        color: #05c3de;
        margin-bottom: 15px;
        max-width: fit-content;

        svg {
          margin: 0 8px 0 0;
        }
      }
    }

    .hyperpay-form {
      min-width: 600px;
      margin-top: 20px;

      .wpwl-form {
        margin: 0;
        padding: 25px;
        min-width: 100%;
        border-radius: 8px;
        border: 1px solid #eee;
        background-color: #f8f8ff;

        .wpwl-label {
          color: #666;
          font-weight: 500;
        }

        .wpwl-control-brand {
          display: none;
        }

        .wpwl-brand-card {
          width: 60px;
        }

        .wpwl-group-submit {
          margin-bottom: 0;
        }

        .wpwl-wrapper>.wpwl-icon {
          top: 13px;
          right: 8px;
          display: flex;
          padding: 4px 0 1px;
          align-items: center;
        }

        .wpwl-button-pay {
          cursor: pointer;
          font-size: 14px;
          font-weight: 700;
          margin-top: 20px;
          transition: 0.3s;
          padding: 10px 25px;
          border-color: transparent;
          border-radius: 0 0 25px 0;
          text-transform: uppercase;
          background-color: #ffb71b !important;

          &:hover {
            color: #ffb71b;
            transition: 0.3s;
            border-color: #ffb71b;
            background-color: transparent !important;
          }
        }

        .wpwl-control,
        .wpwl-wrapper-cvv iframe {
          height: 45px;
          padding: 14px;
          font-size: 14px;
          border-radius: 6px;
          background-color: #fff !important;
        }

        .wpwl-control-cardNumber,
        .wpwl-control-cvv {
          padding: 6px 12px !important;
        }

        .wpwl-wrapper-cvv iframe input {
          background-color: #fff !important;
        }
      }
    }

    .tamara-form {
      border-radius: 8px;

      img {
        width: 75px;
        margin: 0 0 1px 8px;
        border-radius: 10px;
      }
    }
  }

  .order-summary {
    padding: 30px;
    margin-bottom: 25px;
    border: 1px solid #eee;

    .head {
      display: flex;
      justify-content: space-between;

      .go-back-btn {
        cursor: pointer;

        svg {
          margin: 0 8px 0 0;
          transform: translateY(1px);
        }

        font-size: 18px;
        color: #ffb71b;
        font-weight: 600;
      }
    }

    .item {
      display: flex;
      padding: 20px 0 0;
      align-items: center;

      &:not(:last-child) {
        padding: 20px 0;
        border-bottom: 1px solid #eee;
      }

      .thumbnail {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #eee;

        img {
          width: 160px;
          height: 120px;
          object-fit: cover;
          border-radius: 8px;
        }
      }

      .operations {
        width: 100%;
        padding: 0 0 0 25px;

        >div {
          display: flex;
          justify-content: space-between;

          h5 {
            color: #333;
            font-weight: 600;
            margin-bottom: 2px;
          }

          p {
            margin: 0;
            color: #777;
            font-weight: 500;
          }

          .remove {
            cursor: pointer;
            font-size: 20px;
            color: #dc3545;
          }

          h6 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #05c3de;
            line-height: 26px;
          }
        }

        .product {
          margin-top: 10px;
          align-items: center;
        }
      }
    }
  }

  .billing-details {
    padding: 30px;
    border: 1px solid #eee;
    background-color: #fff;

    .order-table {
      width: 100%;
      margin-top: 10px;

      th {
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        &.bill-title {
          color: #333;
          font-weight: 600;
        }
      }

      tr {
        td {
          color: #777;
          padding: 5px 0;

          &:last-child {
            text-align: end;
            font-weight: 500;
          }

          svg {
            margin: 0 7px;
            font-size: 12px;
          }
        }

        &.cart-subtotal,
        &.cart-promo,
        &.cart-total {
          td {
            color: #333;
          }
        }

        &.cart-subtotal td {
          font-weight: 500;
          padding: 15px 0 5px;
        }

        &.cart-promo {
          td {
            font-weight: 500;
            padding-bottom: 15px;
          }
        }

        &.cart-cnd td {
          padding: 0 0 15px;
        }

        &.cart-total {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;

          td {
            font-size: 18px;
            font-weight: 600;
            padding: 15px 0 13px;

            &:last-child {
              color: #05c3de;
            }
          }
        }
      }
    }

    .pay-now {
      margin-top: 30px;
    }
  }

  .input-wrapper {
    display: flex;
    margin-top: 0;
    flex-wrap: wrap;
    align-items: center;

    input {
      width: 300px;
      padding: 14px;
      font-size: 14px;
      border-radius: 6px;
      margin: 0 15px 0 0;
      border: 1px solid #ddd;

      &:focus {
        outline: none;
      }
    }

    button {
      padding: 12px 20px;
    }
  }

  .vat-number {
    .vat-ask {
      margin-bottom: 15px;

      label {
        font-weight: 500;
      }
    }

    margin-bottom: 40px;

    .input-wrapper {
      margin-top: 10px;

      input {
        margin: 0;
      }
    }

    .vat-fields {
      display: flex;
      flex-wrap: wrap;

      >div {
        width: 300px;
        margin: 0 15px 0 0;
      }
    }
  }

  &.card-details {
    .pay-form {
      margin: 0 auto;
      padding: 60px 20px;
      max-width: fit-content;
    }
  }
}

.out-of-stock {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  // margin: 15px 0 0;
  padding: 6px 15px;
  border-radius: 4px;
  max-width: fit-content;
  background-color: #dc3545;
  text-transform: uppercase;
}

.out-of-stock-btn {
  color: #dc3545;
}

.product-detail-tag {
  display: flex;

  &.checkout-tag {
    margin-bottom: 30px;
  }

  .detail-tag {
    width: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    align-items: center;
    padding: 15px 15px 10px;
    background-color: #fff;
    justify-content: flex-start;
    border: 1px solid #d47c84;

    .img-tag {
      top: -14px;
      left: 10px;
      position: absolute;
    }

    .text-tag {
      width: 100%;

      >span {
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
      }

      .tamara-summary {
        text-align: end;
        max-width: fit-content;
        padding: 5px 0 !important;
        min-width: fit-content !important;
      }

      .tamara-summary {
        padding: 10px 0;
        box-shadow: none !important;
        font-family: inherit !important;

        >span {
          cursor: pointer;
        }
      }
    }
  }
}

.tamara-popup__inner * {
  font-family: inherit !important;
}

.collect-notes {
  padding: 30px;
  margin: 20px 0 0;
  border: 1px solid #eee;
  background-color: #fff;

  h5 {
    font-weight: 600;
  }

  .address-details {
    padding: 0;
    max-width: 100%;
    margin: 0 !important;

    span {
      color: #666;
    }

    a {
      display: inline-block;
    }
  }

  &.margin {
    padding: 0;
    border: 0;
    margin: 35px 0 0;
  }
}

/* Input radio button styles */
.radio-label {
  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  .checkmark {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #eee;
  }

  input:checked~.checkmark {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-color: #05c3de;
  }

  .checkmark:after {
    content: "";
    width: 8px;
    height: 8px;
    display: none;
    position: absolute;
    border-radius: 50%;
    background-color: #05c3de;
  }

  input:checked~.checkmark:after {
    display: block;
  }
}

// Arabic layout
.rtl {
  .track-addons .addons-listing {

    h2,
    p {
      text-align: right;
    }
  }

  .addons-listing {
    .card-listing {
      .single-product {
        .meta-data {
          direction: rtl;

          .addon-actions {
            button {
              svg {
                margin: 0 5px 0 0;
              }

              &.rounded-left {
                border-radius: 0 0 15px 0;
              }

              &:last-child {
                border-radius: 0 0 0 15px;
              }
            }
          }
        }
      }
    }

    .skip-btn {
      transform: translateX(-80px);
    }
  }

  .addon-desc-modal {
    .content {
      .bullets {
        p {
          &:first-child {
            border-right: 0;
            padding-left: 12px;
          }

          &:last-child {
            border-right: 1px solid #ddd;
          }
        }
      }

      .cart-btn {
        button {
          svg {
            margin: 0 5px 0 0;
          }
        }
      }

      .recommended-offers-images img {
        margin: 0 0 0 10px;
      }
    }

    .addon-detail-tag .detail-tag .img-tag {
      left: 0;
      right: 9px;
    }
  }

  .offcanvas {
    .offcanvas-body {
      .controls {
        >button {
          &:last-child {
            border-radius: 0 0 0 15px;
          }
        }
      }

      .product {
        p {
          padding: 0 0 0 15px;
        }

        span.remove svg {
          margin: 0 0 0 3px;
        }
      }
    }
  }

  .product {

    span.item-quantity,
    span.variant-quantity {
      margin: 0 7px 0 0;
    }
  }

  .checkout {
    .coupon-content .input-wrapper form {
      label {
        transform: translate(-10px, 13px);
      }

      input {
        padding: 20px 15px 18px 120px;
      }

      button {
        left: 15px;
        right: auto;
      }
    }

    .pay-form {
      .pay-head span svg {
        margin: 0 0 0 8px;
      }

      .card-and-terms {
        .cards label img {
          margin: 0 10px 0 0;
        }
      }

      .hyperpay-form .wpwl-form .wpwl-wrapper>.wpwl-icon {
        left: 8px;
        right: auto;
      }

      .tamara-form img {
        margin: 0 8px 1px 0;
      }
    }

    .order-summary .item .operations {
      padding: 0 25px 0 0;
    }
  }

  .user-cart {
    right: auto;
    left: 15px;
  }
}

// Mobile view
@media (max-width: 991px) {
  .checkout {
    .pay-form {
      .hyperpay-form .wpwl-form .wpwl-label {
        margin-bottom: 5px;
      }
    }

    .billing-details {
      margin-top: 40px;
      padding: 25px 20px;

      .order-table {
        tr {
          td {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .collect-notes,
    .order-summary {
      padding: 25px 20px;
    }
  }
}

@media (max-width: 767px) {
  .track-addons .addons-listing .card-listing {
    padding: 20px;
  }

  .addons-listing {
    h2 {
      font-size: 28px;
      line-height: 32px;
    }

    .container-fluid {
      padding: 0;
    }

    .card-listing {
      padding: 0;
      background-color: transparent;

      h4 {
        text-align: center;
      }

      .single-product {
        .meta-data {
          padding: 20px;
        }
      }

      .slick-slider {
        .slick-list .slick-slide>div {
          padding: 0 10px;
        }

        .slick-arrow.slick-next {
          right: -10px;

          &::before {
            font-size: 24px;
          }
        }

        .slick-arrow.slick-prev {
          left: -10px;

          &::before {
            font-size: 24px;
          }
        }
      }
    }
  }

  .track-addons.checkout {
    .addons-listing {
      .actions {
        a {
          padding: 8px 20px;
        }
      }
    }
  }

  .addon-desc-modal {
    padding: 25px 25px 30px;

    .content {
      margin-top: 20px;

      h2 {
        font-size: 28px;
      }

      h4 {
        font-size: 24px;
      }

      .cart-btn {
        margin-top: 30px;

        p,
        .campaign-metadata {
          margin-top: 15px;
        }
      }

      .bullets {
        margin: 0 0 20px;

        p {
          border: 0;
          padding: 0;
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }

  .user-cart {
    width: 68px;
    height: 66px;

    svg {
      font-size: 28px;
    }

    span {
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
  }

  .checkout {
    .pay-form {
      .hyperpay-form {
        min-width: auto;
      }
    }

    .order-summary {
      h4 {
        margin: 0;
      }

      .item {
        flex-wrap: wrap;

        .thumbnail {
          width: 100%;
          margin-bottom: 25px;

          img {
            width: 100%;
          }
        }

        .operations {
          padding: 0 !important;

          >div .remove {
            font-size: 16px;
          }

          .product {
            flex-wrap: wrap;

            div,
            h6 {
              width: 100%;
            }

            h6 {
              font-size: 18px;
              text-align: end;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .addons-listing {
    .actions {
      margin-top: 35px;

      ul {
        flex-wrap: wrap;

        .skip-btn {
          width: 100%;
          margin-top: 15px;
          transform: translateX(0);
        }
      }
    }
  }

  .checkout {
    .collect-notes {
      margin: 20px 0 0;
    }

    &.card-details .pay-form {
      padding: 40px 20px;
    }
  }
}