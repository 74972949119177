@media (max-width: 1367px) {
  .personal-details .divider > div:not(:first-child) {
    width: 72% !important;
  }

  .personal-details .car-details .divider>div:not(:first-child) {
    width: 100% !important;
  }
}


@media (max-width: 1280px) {
  .wr360_player img {
    margin-left: 100px !important;
  }
}

@media (max-width: 1024px) {
  .est-welcome h2 {
    font-size: 32px;
    line-height: 42px;
  }
  .est-screens::after,
  .est-screens::before,
  .express-car::after,
  .express-car::before,

  .success-screen.design-props::before,
  .checkout.design-props::before,
  .checkout.design-props::after,
  .est-screens .container::before,
  .express-car > form::before,
 
  .checkout.design-props > .container::before {
    display: none;
  }
  .success-screen.design-props::after{
    width: 200px;
  }
}

@media (max-width: 991px) {
  .est-welcome img {
    margin: 40px 0 0;
  }
  .car-images-modal .modal-content {
    padding: 35px;
  }
  .modal-xl,
  .modal-lg {
    max-width: 90%;
  }
  .autohub-modal {
    padding: 30px;
  }
  .image-guide-modal-header h4 {
    font-size: 32px;
  }
  .image-guide-modal-header p {
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto 30px;
  }
  .autohub-modal .close-icon,
  .order-detail-modal .user-detail-icon {
    transform: translate(12px, -12px);
  }
  .rtl .order-detail-modal .user-detail-icon {
    transform: translate(0, -12px);
  }
  .webrotate360 img {
    margin-top: 30px !important;
    margin-left: 40px !important;
  }
}

@media (max-width: 768px) {
  .est-welcome h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  section.mb-padding {
    padding: 40px 15px;
  }
  .est-welcome.mb-padding {
    padding: 40px 30px;
  }
  .appointment-auth .container {
    padding: 0;
    min-width: 100%;
  }
  .appointment-auth .container h4 {
    margin-top: 25px;
  }
  .personal-details .divider,
  .car-details .divider.car-insight,
  .damage-details .divider {
    flex-wrap: wrap;
  }
  .personal-details .car-details .car-insight .divider {
    flex-wrap: nowrap;
  }
  .personal-details .divider > div,
  .personal-details .divider > div:not(:first-child),
  .rtl .personal-details .divider > div:not(:first-child),
  .car-details .divider > div.car-insight,
  .car-details .divider.car-insight > div:not(:first-child),
  .rtl .car-details .divider.car-insight > div:not(:first-child),
  .damage-details .divider > div:not(:first-child),
  .rtl .damage-details .divider > div:not(:first-child) {
    min-width: 100%;
    margin: 0 0 10px;
  }
  .personal-details .car-details .car-insight .divider > div {
    min-width: auto;
  }
  .car-details .divider > div {
    margin: 0 0 10px;
  }
  .hide-mb {
    display: none;
  }
  .steppers button {
    width: 40px;
    height: 40px;
  }
  .steppers #RFS-ConnectorContainer {
    top: calc((2em - -7px) / 2);
    left: calc((-50% + 2em) - 4px);
    right: calc((50% + 2em) - 4px);
  }
  .user-guide {
    flex-wrap: wrap;
  }
  .user-guide span {
    text-align: left;
  }
  .rtl .user-guide span {
    text-align: right;
  }
  .user-guide > div {
    margin: 0 !important;
  }
  .summary-step .data {
    padding: 25px;
    border-radius: 16px;
  }
  .agreements-step label span {
    font-size: 16px;
  }
  .webrotate360 img {
    transform: scale(1.4);
    margin-left: 20px !important;
  }
  .vin-num-modal h4 {
    font-size: 18px;
  }
  .no-comments h4 {
    margin: 50px 0;
  }
}

@media (max-width: 575px) {
  .est-welcome h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .est-welcome .heading > span {
    margin: 0 0 8px 20px;
  }
  .offer-display span.prices {
    font-size: 18px;
  }
  .est-screens input[type="submit"],
  .est-screens button[type="submit"],
  .est-screens .back-btn,
  .car-images-modal input[type="submit"],
  .car-images-modal button[type="submit"],
  .car-images-modal .back-btn {
    font-size: 14px;
    padding: 8px 22px;
  }
  .car-structure {
    margin: -60px 0 -75px;
    transform: scale(0.6);
  }
  .image-upload-area {
    margin-bottom: 0;
  }
  .damage-details .divider {
    margin-bottom: 15px;
  }
  .custom-image-area i.fa-camera {
    font-size: 36px;
  }
  .modal-xl,
  .modal-lg {
    max-width: unset;
  }
  .car-images-modal h4 {
    font-size: 20px;
  }
  .drag-drop-file label h5 {
    padding: 0 15px;
    font-size: 16px;
  }
  .damage-img-inp label h5 {
    font-size: 15px;
  }
  .drag-drop-file label p {
    padding: 0 15px;
    font-size: 12px;
  }
  .car-images-modal .modal-content {
    padding: 12px;
  }
  .image-guide-modal-header {
    margin: 0 0 30px;
  }
  .image-guide-modal-header h4 {
    font-size: 28px;
  }
  .styled-stepper .steppers button img {
    transform: scale(0.85);
  }
  .success-screen h2 {
    font-size: 30px;
  }
  .success-screen h4 {
    font-size: 20px;
  }
  .webrotate360 {
    margin: 15px auto !important;
  }
  .webrotate360 img {
    transform: scale(1);
    margin-left: 0 !important;
    margin-top: 15px !important;
    width: inherit !important;
    height: inherit !important;
  }
  .vehicle-docs h6 {
    font-size: 16px;
    line-height: 22px;
  }
  .vehicle-docs h6 img {
    width: 200px;
  }
  .est-welcome .request-btn button,
  .addons-listing .card-listing .prev-quotes-btn button {
    width: 100%;
    border-radius: 8px !important;
    margin: 0 0 10px 0 !important;
  }
}
